<template lang="pug">
	.default-modal
		.modal-header
			.modal-title {{ financePublisher.legalEntity.name }} - {{ this.financePublisher.month }}/{{ financePublisher.year }}
			a.close(href="javascript:void(0);", @click="$emit('close')")
				i.la.la-times
		.modal-body
			form.modal-body-inner(@submit.prevent="submit()")
				.row
					.col-sm-6
						.form-group
							label Month
							select.form-control(v-model="financePublisher.month", :disabled="!is_new")
								option(v-for="o in monthOptions", :value="o.v") {{ o.t }}
					.col-sm-6
						.form-group
							label Year
							select.form-control(v-model="financePublisher.year", :disabled="!is_new")
								option(v-for="o in yearOptions", :value="o.v") {{ o.v }}
				.row
					.col-sm-6
						.form-group
							label Status
							select.form-control(v-model="financePublisher.status", @change="updateConfirmedDate")
								option(:value="0") Draft
								option(:value="1") Open
								option(:value="2") Paid
								option(:value="3") Closed
								option(:value="4") Postponed
					.col-sm-6
						.form-group
							label Date Confirmed
							date-picker(v-model="financePublisher.date_confirmed", :config="pickerConfig", @dp-change="updateDueDate")
				.row
					.col-sm-6
						.form-group
							label Estimated
								=' '
								small.form-text.text-muted.d-inline Before VAT
							number-input(v-model="financePublisher.estimated_usd", prefix="$ ", readonly, fraction="2")
					.col-sm-6
						.form-group
							label Due Date
							date-picker(v-model="financePublisher.due_date", :config="pickerConfig")
				.row
					.col-sm-6
						.form-group
							label Confirmed
								=' '
								small.form-text.text-muted.d-inline Before VAT
							number-input(v-model="financePublisher.confirmed_usd", prefix="$ ", :readonly="financePublisher.status >= 1", fraction="2")
					.col-sm-6
						.form-group
							label Payment Method
							select.form-control(v-model="financePublisher.payment_method")
								option(value="wire") Wire Transfer
								option(value="paypal") Paypal
								option(value="payoneer") Payoneer
								option(value="offset") Offset
								option(value="prepayment") Prepayment
				.row
					.col-sm-6
						.form-group
							label Paid (USD)
								=' '
								small.form-text.text-muted.d-inline Before VAT
							number-input(v-model="financePublisher.paid_usd", prefix="$ ", fraction="2")
					.col-sm-6
						.form-group
							label Date Paid
							date-picker(v-model="financePublisher.date_paid", :config="pickerConfig")
				.widget-secondary-title
				.row
					.col-sm-12
						doc-list(
							:files="financePublisher.files",
							type="publisher-invoice",
							:metadata="{ legal_entity_id: financePublisher.legal_entity_id, month: financePublisher.month, year: financePublisher.year }"
						)
				.form-group
					label Comments
					textarea.form-control(v-model="financePublisher.comments", rows="5")
				.form-group
					button.btn.btn-primary(type="submit") Save

</template>
<script>
import Vue from 'vue';
import moment from 'moment/moment';

export default {
	name: 'PublisherFinanceModal',
	props: ['financePublisher', 'yearOptions', 'monthOptions', 'is_new'],
	data() {
		return {
			busy: false,
			pickerConfig: {
				format: 'DD/MM/YYYY',
				showClear: true,
				allowInputToggle: true,
				icons: {
					time: 'la la-clock',
					date: 'la la-calendar',
					up: 'la la-chevron-up',
					down: 'la la-chevron-down',
					previous: 'la la-chevron-left',
					next: 'la la-chevron-right',
					today: 'la la-screenshot',
					clear: 'la la-trash',
					close: 'la la-remove',
				},
			},
		};
	},
	methods: {
		async submit() {
			if (this.busy) {
				return;
			}
			this.busy = true;
			try {
				let params = Vue.util.extend({}, this.financePublisher);
				params.is_new = this.is_new;
				if (params.is_new) {
					params.identifier = `${params.legal_entity_id}-${params.month}-${params.year}`;
				}
				await this.$ovReq.post('finance/publisher/save', params);
				this.$emit('close');
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		},

		updateDueDate() {
			if (this.financePublisher.date_confirmed) {
				let dateConfirmed = moment(this.financePublisher.date_confirmed, 'DD/MM/YYYY');
				if (dateConfirmed.isValid()) {
					this.financePublisher.due_date = dateConfirmed
						.add(this.financePublisher.legalEntity.publisher_net_terms, 'days')
						.toDate();
				}
			}
		},

		updateConfirmedDate() {
			if (this.financePublisher.status === 1 && !this.financePublisher.date_confirmed) {
				let dateConfirmed = moment();
				this.financePublisher.date_confirmed = dateConfirmed.toDate();
				this.financePublisher.due_date = dateConfirmed
					.add(this.financePublisher.legalEntity.publisher_net_terms, 'days')
					.toDate();
			}
		},
	},
};
</script>
