<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		.widget
			.widget-header
				h1.title Publishers Finance
			.widget-body

				form.form.form-inline.ov-filters(@submit.prevent="loadData()")
					.form-row-main
						.form-group(v-if="!filters.useDatePicker")
							label Month
							select.form-control(v-model="filters.month")
								option(value="") - All -
								option(v-for="o in monthOptions", :value="o.v") {{ o.t }}
						.form-group(v-if="!filters.useDatePicker")
							label Year
							select.form-control(v-model="filters.year")
								option(value="") - All -
								option(v-for="o in yearOptions", :value="o.v") {{ o.v }}
						.form-group
							ov-date-time-picker(v-if="filters.useDatePicker", v-model="filters.datePicker")
						.form-group.fg-publisher
							label Legal Entity
							multiselect(:multiple="true", :options="legalEntityOptions", v-model="filters.legal_entity_id",
								track-by="v", label="t", deselect-label="", select-label="")
						.form-group
							label Status
							multiselect(:multiple="true", :options="statusOptions", v-model="filters.status",
								track-by="v", label="t", deselect-label="", select-label="")
						.form-group.no-label
							b-checkbox(type="checkbox", v-model="filters.hide_closed") Hide Closed
						.form-group.no-label
							b-checkbox(type="checkbox", v-model="filters.useDatePicker") Date Picker

					.form-row-end
						.form-group
							label &nbsp;
							b-button-group
								b-dropdown(split-button-type="submit", variant="primary", right, split, text="Go")
									b-dropdown-item(@click="loadData(1)")
										i.la.la-download.mr-2
										| Export
									b-dropdown-item(@click="syncMonth()", :disabled="!filters.month || !filters.year")
										i.la.la-refresh.mr-2
										| Sync Month
									b-dropdown-divider
									b-dropdown-text Create new Record
									b-dropdown-form
										b-form-group
											v-select(:options="legalEntityOptions", v-model.number="add_legal_entity", label="t")
										button.btn.btn-primary(type="button", @click="addRecord()", :disabled="!add_legal_entity") Add


				p.clearfix &nbsp;

				.ov-table-wrapper
					table.table.table-bordered.table-hover.table-finance
						thead
							tr
								th Month / Year
								th Status
								th Entity
								th Estimated
								th Confirmed
								th Total (VAT)
								th Due Date
								th Paid
								th Paid On
								th Payment Method
								th Files
								th Uploaded On
								th Comments
								th Actions
						tbody
							tr(v-if="!records.length")
								td(colspan="12") No matching records were found
							tr(v-for="r in records", :class="{'due': r.status === 1 && r.is_due}")
								td
									button.btn.btn-outline-dark(type="button", @click="setDateFilter(r.month, r.year)", style="width: 75px") {{ r.month }} / {{ r.year }}
								td
									button.btn.badge.badge-pill.badge-default(v-if="r.status===0", type="button", @click="addStatusFilter(0)") Draft
									button.btn.badge.badge-pill.badge-danger(v-if="r.status===1", type="button", @click="addStatusFilter(1)") Open
									button.btn.badge.badge-pill.badge-info(v-if="r.status===2", type="button", @click="addStatusFilter(2)") Paid
									button.btn.badge.badge-pill.badge-success(v-if="r.status===3", type="button", @click="addStatusFilter(3)") Closed
									button.btn.badge.badge-pill.badge-primary(v-if="r.status===4", type="button", @click="addStatusFilter(4)") Postponed
								td
									.d-flex.justify-content-between
										.legal-entity
											button.btn.btn-outline-dark(type="button", @click="addLegalFilter(r.legal_entity_id)") {{ r.legalEntity.common_name || r.legalEntity.name }}
											.text-muted.pl-2(v-if="r.legalEntity.common_name") {{ r.legalEntity.name }}
										router-link.btn.btn-default(:to="{name:'legal-entity-edit', params: {id:r.legal_entity_id}}", v-b-tooltip.hover.right, title="Edit")
											i.la.la-pencil
								td
									div {{ r.estimated_usd | toCurrency }}
										i.la.ml-1(v-if="r.o_estimated_usd", :class="{'la-balance-scale-right': r.estimated_usd <= r.o_estimated_usd, 'la-balance-scale-left': r.estimated_usd > r.o_estimated_usd}", v-b-tooltip.hover, :title="'Offset $'+r.o_estimated_usd")
								td
									div {{ r.confirmed_usd | toCurrency }}
										i.la.ml-1(v-if="r.o_confirmed_usd", :class="{'la-balance-scale-right': r.confirmed_usd <= r.o_confirmed_usd, 'la-balance-scale-left': r.confirmed_usd > r.o_confirmed_usd}", v-b-tooltip.hover, :title="'Offset $'+r.o_confirmed_usd")

								td(style="font-weight: bold")
									div {{ r.total_usd | toCurrency }}

								td {{ r.due_date }}
								td {{ r.paid_usd | toCurrency }}
								td {{ r.date_paid }}
								td {{ r.payment_method }}
								td
									span(v-for="f in r.files")
										a(:href="f.full_path", target="_blank", v-b-tooltip, :title="f.name")
											i.la.la-file
								td {{ r.file_upload_date }}
								td {{ r.comments }}
								td.actions
									button.btn.btn-secondary(type="button", @click="editRecord(r)", title="Edit")
										i.la.la-pencil
									| &nbsp;
									button.btn.btn-secondary(type="button", @click="deleteRecord(r)", title="Delete")
										i.la.la-trash

						tfoot(v-if="summary")
							tr
								th(colspan="3") &nbsp;
								th {{ summary.estimated_usd | toCurrency }}
								th {{ summary.confirmed_usd | toCurrency }}
								th {{ summary.total_usd | toCurrency }}
								th {{ summary.paid_usd | toCurrency }}
								th(colspan="5") &nbsp;

</template>
<style lang="scss">
.table-finance {
  .badge {
    font-size: 12px;
  }
  tbody {
    tr {
      &.due {
        background: lighten(#dc3545, 35) !important;
      }
    }
  }
}
.v-select {
  width: 200px;
  .vs__dropdown-menu {
    font-size: 11px;
    li {
      padding: 3px;
    }
  }
}
.multiselect__content-wrapper {
  .multiselect__element {
    .multiselect__option {
      font-size: 10px;
    }
  }
}
</style>
<script>
import Vue from 'vue';
import PublisherFinanceModal from './PublisherFinanceModal';
import moment from 'moment';

export default {
  name: 'FinancePublisher',
  async beforeRouteEnter(to, from, next) {
    let legalEntityIdStr = '' + to.query.legal_entity_id;
    let legalEntityIdList = [];
    if (legalEntityIdStr) {
      legalEntityIdList = legalEntityIdStr
        .split(',')
        .map((id) => +id)
        .filter((id) => id);
    }
    let selectedLegalEntities = [];

    try {
      let legalEntityOptions = await Vue.ovData.legalEntity.getOptions(true);
      if (legalEntityIdList.length) {
        legalEntityOptions.forEach((r) => {
          if (legalEntityIdList.indexOf(r.v) > -1) {
            selectedLegalEntities.push(r);
          }
        });
      }
      next((vm) => {
        vm.legalEntityOptions = legalEntityOptions;
        if (legalEntityIdList.length) {
          vm.filters.legal_entity_id = selectedLegalEntities;
        }
        vm.filters.month = to.query.month || '';
        vm.filters.year = to.query.year || '';
        let statuses = to.query.status || [];
        console.log('typeof statuses', typeof statuses);
        if (typeof statuses === 'string') {
          statuses = [statuses];
        }
        statuses.forEach((s) => vm.addStatusFilter(+s));
        vm.loadData();
        return vm;
      });
    } catch (e) {
      next(false);
    }
  },
  data() {
    return {
      busy: false,
      filters: {
        status: [],
        year: '',
        month: '',
        legal_entity_id: [],
        hide_closed: true,
        useDatePicker: false,
        datePicker: {
          hideTZ: true,
          dateRange: {
            startDate: null,
            endDate: null,
          },
        },
      },
      add_legal_entity: 0,
      legalEntityOptions: [],
      yearOptions: [{ v: 2019 }, { v: 2020 }, { v: 2021 }, { v: 2022 }, { v: 2023 }, { v: 2024 }],
      monthOptions: [
        { v: 1, t: 'January' },
        { v: 2, t: 'February' },
        { v: 3, t: 'March' },
        { v: 4, t: 'April' },
        { v: 5, t: 'May' },
        { v: 6, t: 'June' },
        { v: 7, t: 'July' },
        { v: 8, t: 'August' },
        { v: 9, t: 'September' },
        { v: 10, t: 'October' },
        { v: 11, t: 'November' },
        { v: 12, t: 'December' },
      ],
      statusOptions: [
        { v: 0, t: 'draft' },
        { v: 1, t: 'open' },
        { v: 2, t: 'paid' },
        { v: 3, t: 'closed' },
        { v: 4, t: 'postponed' },
      ],
      records: [],
      summary: null,
    };
  },
  methods: {
    editRecord: function (r) {
      this.$modal.show(
        PublisherFinanceModal,
        {
          financePublisher: Vue.util.extend({}, r),
          is_new: false,
          yearOptions: this.yearOptions,
          monthOptions: this.monthOptions,
        },
        {
          height: 'auto',
          scrollable: true,
        },
        {
          'before-close': async (/*event*/) => {
            this.loadData();
          },
        },
      );
    },
    deleteRecord: function () {
      this.$ovNotify.error('Not implemented');
    },
    addRecord: async function () {
      if (!this.add_legal_entity) {
        return;
      }
      let financePublisher = Vue.ovData.financePublisher.newInstance();
      financePublisher.legal_entity_id = this.add_legal_entity.v;
      financePublisher.legalEntity = {
        name: this.add_legal_entity.t,
        id: this.add_legal_entity.v,
      };
      this.$modal.show(
        PublisherFinanceModal,
        {
          is_new: true,
          financePublisher,
          yearOptions: this.yearOptions,
          monthOptions: this.monthOptions,
        },
        {
          height: 'auto',
          scrollable: true,
        },
        {
          'before-close': async (/*event*/) => {
            this.loadData();
          },
        },
      );
    },

    async loadData(doExport) {
      if (this.busy) {
        return;
      }
      let params = {
        do_export: doExport ? doExport : 0,
        status: this.filters.status.map((r) => r.v).join(','),
        legal_entity_id: this.filters.legal_entity_id.map((r) => r.v).join(','),
        hide_closed: this.filters.hide_closed ? 1 : 0,
      };
      if (this.filters.useDatePicker) {
        params.date_start = moment(this.filters.datePicker.dateRange.startDate).format(
          'YYYY-MM-DD',
        );
        params.date_end = moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD');
      } else {
        params.year = this.filters.year;
        params.month = this.filters.month;
      }
      this.busy = true;
      try {
        let resp = await this.$ovReq.get('finance/publisher/getList', { params });
        if (doExport === 1) {
          window.open(resp.path);
        } else {
          this.summary = resp.summary;
          this.records = resp.records;
        }
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    addStatusFilter(statusValue) {
      let status = this.statusOptions.find((o) => o.v === statusValue);
      let idx = this.filters.status.indexOf(status);
      if (idx === -1) {
        this.filters.status.push(status);
      } else {
        this.filters.status.splice(idx, 1);
      }
    },

    addLegalFilter(legalValue) {
      let legalEntity = this.legalEntityOptions.find((o) => o.v === legalValue);
      let idx = this.filters.legal_entity_id.indexOf(legalEntity);
      if (idx === -1) {
        this.filters.legal_entity_id.push(legalEntity);
      } else {
        this.filters.legal_entity_id.splice(idx, 1);
      }
    },

    setDateFilter(month, year) {
      if (this.filters.month !== month || this.filters.year !== year) {
        this.filters.month = month;
        this.filters.year = year;
      } else {
        this.filters.month = '';
        this.filters.year = '';
      }
    },

    async syncMonth() {
      if (this.busy) {
        return;
      }
      this.busy = true;
      let params = {
        year: this.filters.year,
        month: this.filters.month,
      };
      try {
        await this.$ovReq.post('finance/publisher/syncMonth', params);
        this.busy = false;
        await this.loadData();
        return this.$ovNotify.success('Synced successfully');
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },
  },
};
</script>
